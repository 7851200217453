import './InitialHomepage.css';
function InitialHomepage() {
  return (
    <div className="initial-homepage">
      <h1>Åsö Schacksällskap</h1>
      <div className="statutes">
        <p>1. Schack</p>
        <p>2. Rave</p>
        <p>3. Konst</p>
      </div>
    </div>
  );
}

export default InitialHomepage;
